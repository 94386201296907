import axios from "axios";
const baseApiUrl = process.env.REACT_APP_API_URL;

async function POSTMethodWithoutAuth(url, payload) {
  try {
    const config = {
      method: "POST",
      url: baseApiUrl + url,
      data: payload,
    };
    let res = await axios(config);
    return res;
  } catch (error) {
    this.handleAxiosError(error);
  }
}

async function POSTMethodAuth(url, payload) {
  try {
    const config = {
      method: "POST",
      url: baseApiUrl + url,
      data: payload,
      headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
    };
    let res = await axios(config);
    return res;
  } catch (error) {
    this.handleAxiosError(error);
  }
}

async function GETMethodAuth(url, queryparam = null) {
  try {
    const config = {
      method: "GET",
      url: baseApiUrl + url,
      data: queryparam,
      headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
    };
    let res = await axios(config);
    return res;
  } catch (error) {
    this.handleAxiosError(error);
  }
}

async function GETMethodAuthWithParam(url, queryparam) {
  try {
    const config = {
      method: "GET",
      url: baseApiUrl + url,
      params: queryparam,
      headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` },
    };
    let res = await axios(config);
    return res;
  } catch (error) {
    this.handleAxiosError(error);
  }
}

async function PostMethod(url, payload) {
  try {
    const config = {
      method: "POST",
      url: baseApiUrl + url,
      data: payload,
    };
    let res = await axios(config);
    return res;
  } catch (error) {
    return error.response.status;
  }
}

export {
  POSTMethodWithoutAuth,
  POSTMethodAuth,
  GETMethodAuth,
  GETMethodAuthWithParam,
  PostMethod,
};
