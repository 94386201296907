import { POSTMethodAuth, POSTMethodWithoutAuth, PostMethod } from "..";

async function authenticationAppToken() {
  let body = {
    Code: process.env.REACT_APP_USER_CODE,
    Username: process.env.REACT_APP_USER_NAME,
    Password: process.env.REACT_APP_PASSWORD
  };
  let authorization = await POSTMethodWithoutAuth("/api/token",body);
  if (authorization.data.access_token) {
    return authorization.data.access_token;
  }
}

async function authenticationAPI(body) {
  let login = await POSTMethodAuth("/api/dl_validate_policy", body)
  if (login.data.access_token) {
    window.sessionStorage.setItem("token", login.data.access_token);
  }
  return login.data;
}

async function validatePolicyNumber(policyNumber) {
  const param = {
    policy: policyNumber
  }
  let policy = await PostMethod("/api/get_policy", param);
  return policy;
}


export {
    authenticationAPI,
    validatePolicyNumber
}